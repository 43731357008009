var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.transaction)?_c('section',{attrs:{"id":"widget-grid"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"},[_c('div',{staticClass:"transaction-detail-card"},[_c('widget',{attrs:{"title":_vm.$tc('phrases.providerSpecificInformation'),"show-spinner":false,"color":"green"}},[_c('md-card',[_c('md-card-content',[_c(_vm.providerDetail,{tag:"component",attrs:{"ot":_vm.ot}})],1)],1)],1)],1)]),_c('div',{staticClass:"md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"},[_c('div',{staticClass:"transaction-detail-card"},[_c('widget',{attrs:{"title":_vm.$tc('words.detail', 2),"show-spinner":false}},[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("words.sender"))+" ")]),_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_vm._v(" "+_vm._s(_vm.transaction.sender)+" ")])]),_c('hr',{staticClass:"hr-d"}),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("words.amount"))+" ")]),_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.transaction.amount))+" ")])]),_c('hr',{staticClass:"hr-d"}),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("phrases.paymentType"))+" ")]),_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_c('span',{domProps:{"textContent":_vm._s(
                        _vm.transaction.type === 'energy'
                          ? _vm.$tc('words.energy')
                          : _vm.$tc('phrases.deferredPayment')
                      )}}),_c('div',{staticStyle:{"margin-left":"0.2em"}},[(
                          _vm.transaction.type === 'energy' && _vm.transaction.token
                        )?_c('small',[_vm._v(" ("+_vm._s(_vm.readable(_vm.transaction.token.load))+"kWh) ")]):(
                          _vm.transaction.type === 'deferred_payment' &&
                          _vm.transaction.token
                        )?_c('small',[_vm._v(" ("+_vm._s(_vm.readable(_vm.transaction.token.load))+"day's) ")]):_vm._e()])])]),_c('hr',{staticClass:"hr-d"}),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("words.deviceType"))+" ")]),_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_vm._v(" "+_vm._s(_vm.$tc(`words.${_vm.transaction.device.device_type}`))+" ")])]),_c('hr',{staticClass:"hr-d"}),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("words.device"))+" ")]),(
                      _vm.transaction.payment_histories[0].paymentHistory &&
                      _vm.transaction.device.device_type === 'meter'
                    )?_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                        path: '/meters/' + _vm.transaction.message,
                      }}},[_vm._v(" "+_vm._s(_vm.transaction.message)+" ")])],1):_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_vm._v(" "+_vm._s(_vm.transaction.message)+" ")])]),_c('hr',{staticClass:"hr-d"}),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("words.customer"))+" ")]),(_vm.transaction.payment_histories[0].paymentHistory)?_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                        path: '/people/' + _vm.personId,
                      }}},[_vm._v(" "+_vm._s(_vm.personName)+" ")])],1):_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_vm._v(" "+_vm._s(_vm.transaction.payment_histories[0].personName)+" ")])]),_c('hr',{staticClass:"hr-d"}),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("words.date"))+" ")]),_c('div',{staticClass:"md-layout-item md-subheader n-font"},[_vm._v(" "+_vm._s(_vm.timeForHuman(_vm.transaction.created_at))+" "),_c('small',{staticStyle:{"margin-left":"0.2rem"}},[_vm._v(" ("+_vm._s(_vm.timeForTimeZone(_vm.transaction.created_at))+") ")])])])])],1)],1)],1)])]),_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('div',{staticClass:"transaction-detail-card"},[_c('widget',{attrs:{"title":"Transaction Processing","show-spinner":false,"color":"green"}},[_c('md-card',[(
                  _vm.transaction.original_transaction_type ===
                  'third_party_transaction'
                )?_c('div',[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter md-size-100"},[_c('ul',{staticStyle:{"margin":"auto"}},[_c('li',[_vm._v(" "+_vm._s(_vm.$tc("phrases.untraceableTransaction"))+" ")])])])])],1):_c('div',[(_vm.ot.status === 1)?_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter md-size-100"},[_c('div',{staticClass:"md-layout-item md-size-55",staticStyle:{"margin":"auto"}},[_c('payment-history-chart',{attrs:{"paymentdata":_vm.transaction.payment_histories}})],1),_c('div',{staticClass:"md-layout-item md-size-45",staticStyle:{"max-height":"320px","overflow-y":"scroll"}},[(_vm.transaction.payment_histories[0].paymentHistory)?_c('md-table',[_c('md-table-row',[_c('md-table-head',[_vm._v(" "+_vm._s(_vm.$tc("phrases.paidFor"))+" ")]),_c('md-table-head',[_vm._v(" "+_vm._s(_vm.$tc("words.amount"))+" ")])],1),_vm._l((_vm.transaction.payment_histories),function(p,i){return _c('md-table-row',{key:i},[_c('md-table-cell',[_c('p',[_vm._v(" "+_vm._s(p.payment_type)+" ")])]),_c('md-table-cell',[_vm._v(" "+_vm._s(_vm.moneyFormat(p.amount))+" ")])],1)})],2):_vm._e()],1)])]):_vm._e(),(_vm.ot.status === -1)?_c('md-card-content',[_c('h2',[_vm._v("Transaction cancelled")]),_c('md-list',{staticClass:"md-double-line"},[_c('md-subheader',{staticStyle:{"color":"#a81e10"}},[_vm._v(" "+_vm._s(_vm.$tc("phrases.transactionCancelled"))+" ")]),_vm._l((_vm.ot.conflicts),function(conflict){return _c('md-list-item',{key:conflict.id},[_c('span',{staticClass:"margin-top-5"},[_vm._v(" "+_vm._s(conflict.state)+" ")])])})],2)],1):_vm._e()],1)])],1)],1)]),(_vm.transaction.sms)?_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('div',{staticClass:"transaction-detail-card"},[_c('widget',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.transaction.original_transaction_type !== 'agent_transaction' &&
              _vm.transaction.original_transaction_type !==
                'third_party_transaction'
            ),expression:"\n              transaction.original_transaction_type !== 'agent_transaction' &&\n              transaction.original_transaction_type !==\n                'third_party_transaction'\n            "}],attrs:{"title":_vm.$tc('phrases.outgoingSms'),"show-spinner":false,"color":"red"}},[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter md-size-100"},[_c('div',{staticClass:"md-layout-item md-subheader md-size-20"},[_vm._v(" "+_vm._s(_vm.$tc("words.to"))+" ")]),_c('div',{staticClass:"md-layout-item md-subheader md-size-80"},[_vm._v(" "+_vm._s(_vm.transaction.sms.receiver)+" ")])]),_c('div',{staticClass:"md-layout md-gutter md-size-100"},[_c('div',{staticClass:"md-layout-item md-subheader md-size-20"},[_vm._v(" "+_vm._s(_vm.$tc("words.body"))+" ")]),_c('div',{staticClass:"md-layout-item md-subheader md-size-75 message-box"},[_vm._v(" "+_vm._s(_vm.transaction.sms.body)+" ")])])])],1)],1)],1)]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }