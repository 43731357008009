<template>
  <div></div>
</template>

<script>
export default {
  name: "LoginFooter",
}
</script>

<style scoped></style>
