<template>
  <Login />
</template>
<script>
import Login from "@/modules/Login/Login.vue"
export default {
  name: "LoginPage",
  components: {
    Login,
  },
}
</script>

<style lang="css"></style>
