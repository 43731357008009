<template>
  <NewTarget />
</template>

<script>
import NewTarget from "@/modules/Target/NewTarget.vue"

export default {
  name: "NewTargetPage",
  components: { NewTarget },
}
</script>
