<template>
  <Transactions />
</template>
<script>
import Transactions from "@/modules/Transactions/Transactions"
export default {
  name: "TransactionsPage",
  components: { Transactions },
}
</script>
