<template>
  <Tariffs />
</template>
<script>
import Tariffs from "@/modules/Tariff/Tariffs"

export default {
  name: "TariffsPage",
  components: { Tariffs },
}
</script>
