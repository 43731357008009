var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('widget',{attrs:{"id":"meter-list","title":_vm.title,"paginator":true,"paging_url":_vm.meterService.pagingUrl,"route_name":_vm.meterService.routeName,"show_per_page":true,"subscriber":_vm.subscriber,"color":"green","button":true,"buttonIcon":"cloud_download","button-text":_vm.buttonText,"emptyStateLabel":_vm.label,"emptyStateButtonText":_vm.buttonText,"newRecordButton":false},on:{"widgetAction":function($event){return _vm.syncMeters()}}},[_c('md-table',{attrs:{"md-sort":"id","md-sort-order":"asc","md-card":""},scopedSlots:_vm._u([{key:"md-table-row",fn:function({ item }){return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"ID","md-sort-by":"id"}},[_vm._v(" "+_vm._s(item.id)+" ")]),_c('md-table-cell',{attrs:{"md-label":"DCU","md-sort-by":"terminalId"}},[_vm._v(" "+_vm._s(item.terminalId)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Meter Name","md-sort-by":"meterName"}},[_vm._v(" "+_vm._s(item.meterName)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Meter Address","md-sort-by":"meterAddress"}},[_vm._v(" "+_vm._s(item.meterAddress)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Owner","md-sort-by":"owner"}},[_vm._v(" "+_vm._s(item.owner)+" ")]),_c('md-table-cell',{attrs:{"md-label":"#"}},[_c('md-button',{staticClass:"md-icon-button",on:{"click":() =>
                _vm.$router.push('/kelin-meters/kelin-meter/status/' + item.id)}},[_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Status")]),_c('md-icon',[_vm._v("remove_red_eye")])],1),_c('md-button',{staticClass:"md-icon-button",on:{"click":() =>
                _vm.$router.push(
                  '/kelin-meters/kelin-meter/minutely-consumptions/' +
                    item.id,
                )}},[_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Minutely Movements")]),_c('md-icon',[_vm._v("swap_vert")])],1),_c('md-button',{staticClass:"md-icon-button",on:{"click":() =>
                _vm.$router.push(
                  '/kelin-meters/kelin-meter/daily-consumptions/' + item.id,
                )}},[_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Daily Movements")]),_c('md-icon',[_vm._v("swap_vert")])],1)],1)],1)}}]),model:{value:(_vm.meterService.list),callback:function ($$v) {_vm.$set(_vm.meterService, "list", $$v)},expression:"meterService.list"}})],1),(_vm.loading)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e(),_c('redirection-modal',{attrs:{"redirection-url":_vm.redirectionUrl,"dialog-active":_vm.redirectDialogActive,"imperative-item":'valid API Credentials'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }