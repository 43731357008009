<template>
  <Reports />
</template>

<script>
import Reports from "@/modules/ExportedReports/Reports"

export default {
  name: "ReportsPage",
  components: { Reports },
}
</script>

<style scoped></style>
