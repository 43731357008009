<template>
  <ConnectionTypeDetail />
</template>

<script>
import ConnectionTypeDetail from "@/modules/ConnectionTypes/ConnectionTypeDetail.vue"
export default {
  name: "ConnectionTypePage",
  components: { ConnectionTypeDetail },
}
</script>

<style scoped></style>
