var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.paginator && _vm.paginator.totalPage > 1)?_c('div',{staticClass:"md-layout md-gutter md-size-100 pagination-area"},[_c('div',{staticClass:"md-layout-item md-size-25 pagination-entry"},[_vm._v(" "+_vm._s(_vm.$tc("phrases.paginateLabels", 1, { from: _vm.paginator.from, to: _vm.paginator.to, total: _vm.paginator.totalEntries, }))+" ")]),(_vm.show_per_page)?_c('div',{staticClass:"md-layout-item md-size-20 pagination-per-page"}):_vm._e(),_c('div',{staticClass:"md-layout-item",class:{
      'md-size-70': !_vm.show_per_page,
      'md-size-50': _vm.show_per_page,
    }},[_c('div',{staticClass:"md-layout pagination"},[(_vm.show_per_page)?_c('span',[_vm._v(_vm._s(_vm.$tc("phrases.perPage"))+":")]):_vm._e(),(_vm.show_per_page)?_c('select',{attrs:{"name":"per_page","id":"per_page"},on:{"change":_vm.defaultItemsPerPage}},[_c('option',{attrs:{"value":"15"}},[_vm._v("15")]),_c('option',{attrs:{"value":"25"}},[_vm._v("25")]),_c('option',{attrs:{"value":"30"}},[_vm._v("30")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")]),_c('option',{attrs:{"value":"200"}},[_vm._v("200")]),_c('option',{attrs:{"value":"300"}},[_vm._v("300")])]):_vm._e(),(_vm.paginator.totalPage >= 5)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.goPage),expression:"goPage"}],attrs:{"type":"number"},domProps:{"value":(_vm.goPage)},on:{"input":function($event){if($event.target.composing)return;_vm.goPage=$event.target.value}}}):_vm._e(),(_vm.paginator.totalPage >= 5)?_c('button',{on:{"click":function($event){return _vm.changePage(_vm.goPage)}}},[_vm._v(" Go ")]):_vm._e(),_c('a',{class:{ disabled: _vm.paginator.currentPage === 1 },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.changePage(1)}}},[_c('md-icon',{class:{ disabled: _vm.paginator.currentPage === 1 }},[_vm._v(" first_page ")])],1),_c('a',{class:{ disabled: _vm.paginator.currentPage === 1 },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.changePage(--_vm.paginator.currentPage)}}},[_c('md-icon',{class:{ disabled: _vm.paginator.currentPage === 1 }},[_vm._v(" chevron_left ")])],1),_c('span',[_vm._v(" "+_vm._s(_vm.paginator.currentPage)+" of "+_vm._s(_vm.formatTotalPages(_vm.paginator.totalPage))+" ")]),_c('a',{class:{
          disabled: _vm.paginator.currentPage === _vm.paginator.totalPage,
        },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.changePage(++_vm.paginator.currentPage)}}},[_c('md-icon',{class:{
            disabled: _vm.paginator.currentPage === _vm.paginator.totalPage,
          }},[_vm._v(" chevron_right ")])],1),_c('a',{class:{
          disabled: _vm.paginator.currentPage === _vm.paginator.totalPage,
        },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.changePage(_vm.paginator.totalPage)}}},[_c('md-icon',{class:{
            disabled: _vm.paginator.currentPage === _vm.paginator.totalPage,
          }},[_vm._v(" last_page ")])],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }