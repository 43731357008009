var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('md-dialog',{staticStyle:{"max-width":"60rem","margin":"auto","overflow":"auto"},attrs:{"md-active":_vm.showAddShs},on:{"update:mdActive":function($event){_vm.showAddShs=$event},"update:md-active":function($event){_vm.showAddShs=$event}}},[_c('md-dialog-title',[_c('h4',{staticStyle:{"font-size":"1.2rem","margin":"0","border-bottom":"solid 1px #dedede"}},[_vm._v(" "+_vm._s(_vm.$tc("words.shs", 1))+" ")])]),_c('md-dialog-content',{staticClass:"md-layout-item md-size-100",staticStyle:{"overflow-y":"auto"}},[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('form',{staticClass:"md-layout md-gutter",attrs:{"data-vv-scope":"shs-add-form"}},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('md-field',{class:{
                  'md-invalid': _vm.errors.has('shs-add-form.serial_number'),
                }},[_c('label',{attrs:{"for":"serial_number"}},[_vm._v(" "+_vm._s(_vm.$tc("phrases.serialNumber"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:8|max:11'),expression:"'required|min:8|max:11'"}],attrs:{"id":"serial_number","name":"serial_number"},model:{value:(_vm.solarHomeSystemService.shs.serialNumber),callback:function ($$v) {_vm.$set(_vm.solarHomeSystemService.shs, "serialNumber", $$v)},expression:"solarHomeSystemService.shs.serialNumber"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("shs-add-form.serial_number"))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                  'md-invalid': _vm.errors.has('shs-add-form.manufacturer'),
                }},[_c('label',{attrs:{"for":"manufacturers"}},[_vm._v(" "+_vm._s(_vm.$tc("words.manufacturer"))+" ")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"manufacturer","id":"manufacturer"},model:{value:(_vm.solarHomeSystemService.shs.manufacturerId),callback:function ($$v) {_vm.$set(_vm.solarHomeSystemService.shs, "manufacturerId", $$v)},expression:"solarHomeSystemService.shs.manufacturerId"}},_vm._l((_vm.manufacturers),function(manufacturer){return _c('md-option',{key:manufacturer.id,attrs:{"value":manufacturer.id}},[_vm._v(" "+_vm._s(manufacturer.name)+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("shs-add-form.manufacturer"))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',{class:{
                  'md-invalid': _vm.errors.has('shs-add-form.appliance'),
                }},[_c('label',{attrs:{"for":"appliances"}},[_vm._v(" "+_vm._s(_vm.$tc("words.appliance"))+" ")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"appliance","id":"appliance"},model:{value:(_vm.solarHomeSystemService.shs.assetId),callback:function ($$v) {_vm.$set(_vm.solarHomeSystemService.shs, "assetId", $$v)},expression:"solarHomeSystemService.shs.assetId"}},_vm._l((_vm.appliances),function(appliance){return _c('md-option',{key:appliance.id,attrs:{"value":appliance.id}},[_vm._v(" "+_vm._s(appliance.name)+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("shs-add-form.appliance"))+" ")])],1)],1)])])])]),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-raised md-primary",attrs:{"role":"button","disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")]),_c('md-button',{staticClass:"md-raised",attrs:{"role":"button"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$tc("words.close"))+" ")])],1),(_vm.loading)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }