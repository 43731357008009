<template>
  <Client />
</template>
<script>
import Client from "@/modules/Client/Client.vue"

export default {
  name: "ClientPage",
  components: {
    Client,
  },
}
</script>
