<template>
  <AddMiniGrid />
</template>

<script>
import AddMiniGrid from "@/modules/MiniGrid/AddMiniGrid.vue"

export default {
  name: "NewMiniGridPage",
  components: { AddMiniGrid },
}
</script>
