<template>
  <ForgotPassword />
</template>
<script>
import ForgotPassword from "@/modules/ForgotPassword/ForgotPassword.vue"
export default {
  name: "ForgotPasswordPage",
  components: {
    ForgotPassword,
  },
}
</script>

<style lang="css"></style>
