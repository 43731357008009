var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AddEBikeModal',{attrs:{"showAddEBike":_vm.showAddEBike},on:{"hideAddEBike":() => {
        _vm.showAddEBike = false
      },"created":_vm.updateList}}),_c('EBikeDetailModal',{attrs:{"e-bike":_vm.eBikeService.eBike,"showEBikeDetail":_vm.showEBikeDetail},on:{"hideEBikeDetail":() => {
        _vm.showEBikeDetail = false
      }}}),_c('widget',{attrs:{"id":'eBike-list',"title":_vm.$tc('words.e_bike', 2),"paginator":_vm.eBikeService.paginator,"search":true,"subscriber":_vm.subscriber,"button":true,"button-text":_vm.$tc('phrases.newEBike'),"route_name":'/e-bikes',"color":"green"},on:{"widgetAction":() => {
        _vm.showAddEBike = true
      }}},[_c('md-table',{staticStyle:{"margin-left":"0"},attrs:{"md-card":""}},[_c('md-table-row',[_c('md-table-head',[_vm._v(" "+_vm._s(_vm.$tc("phrases.serialNumber"))+" ")]),_c('md-table-head',[_vm._v(" "+_vm._s(_vm.$tc("words.manufacturer"))+" ")]),_c('md-table-head',[_vm._v(_vm._s(_vm.$tc("words.name")))]),_c('md-table-head',[_vm._v(_vm._s(_vm.$tc("words.owner")))]),_c('md-table-head',[_vm._v(" "+_vm._s(_vm.$tc("phrases.lastUpdate"))+" ")])],1),_vm._l((_vm.eBikeService.list),function(eBike){return _c('md-table-row',{key:eBike.id,staticStyle:{"cursor":"pointer !important"},on:{"click":function($event){return _vm.detail(eBike.serialNumber)}}},[_c('md-table-cell',[_vm._v(_vm._s(eBike.serialNumber))]),_c('md-table-cell',[_vm._v(_vm._s(eBike.manufacturer.name))]),_c('md-table-cell',[_vm._v(_vm._s(eBike.appliance.name))]),(eBike.device?.person)?_c('md-table-cell',[_c('router-link',{attrs:{"to":`/people/${eBike.device.person.id}`}},[_vm._v(" "+_vm._s(`${eBike.device.person.name} ${eBike.device.person.surname}`)+" ")])],1):_c('md-table-cell',[_vm._v("-")]),_c('md-table-cell',[_vm._v(" "+_vm._s(_vm.timeForTimeZone(eBike.updatedAt))+" ")])],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }