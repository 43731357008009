<template>
  <User />
</template>

<script>
import User from "@/modules/Profile/User.vue"

export default {
  name: "ProfilePage",
  components: { User },
}
</script>
