<template>
  <UserManagement />
</template>

<script>
import UserManagement from "@/modules/Profile/UserManagement"
export default {
  name: "ProfileManagementPage",
  components: { UserManagement },
}
</script>
