var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('widget',{attrs:{"hidden":!_vm.addNewAsset,"title":"Add Appliance","color":"red"}},[_c('md-card',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100"},[_c('md-card-content',[_c('form',{ref:"assetForm",staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.appliance')),
                  }},[_c('label',{attrs:{"for":"appliance"}},[_vm._v(" "+_vm._s(_vm.$tc("words.appliance"))+" ")]),_c('md-select',{attrs:{"name":_vm.$tc('words.appliance'),"id":"appliance"},model:{value:(_vm.applianceService.appliance.assetTypeId),callback:function ($$v) {_vm.$set(_vm.applianceService.appliance, "assetTypeId", $$v)},expression:"applianceService.appliance.assetTypeId"}},[_c('md-option',{attrs:{"disabled":"","value":""}},[_vm._v(" --"+_vm._s(_vm.$tc("words.select"))+"-- ")]),_vm._l((_vm.assetTypeService.list),function(applianceType){return _c('md-option',{key:applianceType.id,attrs:{"value":applianceType.id}},[_vm._v(" "+_vm._s(applianceType.name)+" ")])})],2),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.appliance")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.name')),
                  }},[_c('label',[_vm._v(_vm._s(_vm.$tc("words.name")))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:4'),expression:"'required|min:4'"}],attrs:{"placeholder":_vm.$tc('words.name'),"type":"text","name":_vm.$tc('words.name'),"id":"asset"},model:{value:(_vm.applianceService.appliance.name),callback:function ($$v) {_vm.$set(_vm.applianceService.appliance, "name", $$v)},expression:"applianceService.appliance.name"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.name")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.price')),
                  }},[_c('label',[_vm._v(_vm._s(_vm.$tc("words.price")))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric'),expression:"'required|numeric'"}],attrs:{"placeholder":_vm.$tc('words.price'),"type":"text","name":_vm.$tc('words.price'),"id":"asset_price"},model:{value:(_vm.applianceService.appliance.price),callback:function ($$v) {_vm.$set(_vm.applianceService.appliance, "price", $$v)},expression:"applianceService.appliance.price"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.price")))+" ")])],1)],1)]),(_vm.loading)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e()],1)],1)]),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised md-primary",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.saveAppliance()}}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")]),_c('md-button',{staticClass:"md-raised",on:{"click":function($event){return _vm.closeAddComponent()}}},[_vm._v(" "+_vm._s(_vm.$tc("words.close"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }