<template>
  <AgentList />
</template>

<script>
import AgentList from "@/modules/Agent/AgentList.vue"

export default {
  name: "AgentsPage",
  components: { AgentList },
}
</script>
