<template>
  <Meter value="" />
</template>

<script>
import Meter from "@/modules/Meter/Meter.vue"

export default {
  name: "MeterPage",
  components: { Meter },
}
</script>

<style scoped></style>
