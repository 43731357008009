<template>
  <EBikes />
</template>

<script>
import EBikes from "@/modules/EBikes/EBikes.vue"

export default {
  name: "EBikesPage",
  components: { EBikes },
}
</script>

<style scoped></style>
