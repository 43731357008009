<template>
  <Clients />
</template>
<script>
import Clients from "@/modules/Client/Clients.vue"

export default {
  name: "ClientsPage",
  components: { Clients },
}
</script>
