var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[(_vm.newLabel)?_c('widget',{attrs:{"title":_vm.$tc('phrases.newCategory'),"color":"red"}},[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:{
                'md-invalid': _vm.errors.has(_vm.$tc('words.name')),
              }},[_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"type":"text","placeholder":_vm.$tc('words.name'),"name":_vm.$tc('words.name'),"id":"name"},model:{value:(_vm.ticketLabelService.newLabelName),callback:function ($$v) {_vm.$set(_vm.ticketLabelService, "newLabelName", $$v)},expression:"ticketLabelService.newLabelName"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.name")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:{
                'md-invalid': _vm.errors.has(_vm.$tc('phrases.selectColor')),
              }},[_c('label',[_vm._v(_vm._s(_vm.$tc("phrases.selectColor")))]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$tc('phrases.selectColor'),"id":"color"},model:{value:(_vm.ticketLabelService.currentColor),callback:function ($$v) {_vm.$set(_vm.ticketLabelService, "currentColor", $$v)},expression:"ticketLabelService.currentColor"}},_vm._l((_vm.ticketLabelService.colors),function(index,colorName){return _c('md-option',{key:colorName,attrs:{"value":colorName}},[_vm._v(" "+_vm._s(colorName)+" "),_c('span',{staticClass:"colored-box",staticStyle:{"margin-left":"1rem","max-width":"100px"},style:({
                      backgroundColor: _vm.ticketLabelService.colors[colorName],
                    })})])}),1),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("phrases.selectColor")))+" ")])],1)],1)]),_c('div',{staticClass:"md-layout md-subheader"},[_c('md-checkbox',{staticClass:"form-control",attrs:{"id":"outsourcing"},model:{value:(_vm.ticketLabelService.outSourcing),callback:function ($$v) {_vm.$set(_vm.ticketLabelService, "outSourcing", $$v)},expression:"ticketLabelService.outSourcing"}},[_vm._v(" "+_vm._s(_vm.$tc("words.outsourcing"))+" ")])],1),_c('div',{staticClass:"md-layout"},[_c('span',{staticClass:"md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("phrases.ticketLabels", 1))+" ")])]),_c('div',{staticClass:"md-layout"},[_c('span',{staticClass:"md-subheader"},[_vm._v(" "+_vm._s(_vm.$tc("phrases.ticketLabels", 2, { email: " ako@inensus.com", }))+" ")])])]),(_vm.loading)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e(),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised md-primary",on:{"click":_vm.saveLabel}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")]),_c('md-button',{staticClass:"md-raised md-accent",on:{"click":() => {
              _vm.newLabel = false
            }}},[_vm._v(" "+_vm._s(_vm.$tc("words.close"))+" ")])],1)],1)],1):_vm._e(),_c('widget',{attrs:{"title":_vm.$tc('phrases.ticketCategories'),"button":true,"button-text":_vm.$tc('phrases.newCategory'),"color":"green","subscriber":_vm.subscriber},on:{"widgetAction":() => {
        _vm.newLabel = true
      }}},[_c('md-card',[_c('md-card-content',[_c('md-table',[_c('md-table-row',_vm._l((_vm.headers),function(item,index){return _c('md-table-head',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),1),_vm._l((_vm.ticketLabelService.list),function(label,index){return _c('md-table-row',{key:index},[_c('md-table-cell',[_vm._v(_vm._s(label.id))]),_c('md-table-cell',[_vm._v(" "+_vm._s(label.label_name)+" ")]),_c('md-table-cell',[_c('span',{staticClass:"colored-box",style:({
                  backgroundColor:
                    _vm.ticketLabelService.colors[label.label_color],
                })}),_vm._v(" "+_vm._s(label.label_color)+" ")]),_c('md-table-cell',[(label.out_source === 0)?_c('md-icon',[_vm._v("cancel")]):_c('md-icon',[_vm._v("check")])],1)],1)})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }