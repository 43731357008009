<template>
  <SoldApplianceDetail />
</template>

<script>
import SoldApplianceDetail from "@/modules/Client/Appliances/SoldApplianceDetail"

export default {
  name: "SoldApplianceDetailPage",
  components: { SoldApplianceDetail },
}
</script>

<style scoped></style>
