<template>
  <Settings />
</template>

<script>
import Settings from "@/modules/Settings/Settings"
export default {
  name: "SettingsPage",
  components: { Settings },
}
</script>
