<template>
  <AddCluster />
</template>

<script>
import AddCluster from "@/modules/Cluster/AddCluster.vue"

export default {
  name: "NewClusterPage",
  components: { AddCluster },
}
</script>
