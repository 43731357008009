var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('widget',{attrs:{"title":_vm.$tc('phrases.newCluster', 1),"color":"green"}},[_c('md-card',{staticClass:"md-layout-item md-size-100"},[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-large-size-33 md-medium-size-33 md-small-size-100"},[_c('md-field',{class:{
                'md-invalid': _vm.errors.has(_vm.$tc('words.name')),
              }},[_c('label',[_vm._v(_vm._s(_vm.$tc("words.name")))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"name":_vm.$tc('words.name'),"id":"clusterName"},model:{value:(_vm.clusterName),callback:function ($$v) {_vm.clusterName=$$v},expression:"clusterName"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.name")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-large-size-33 md-medium-size-33 md-small-size-100"},[_c('user-list',{on:{"userSelected":_vm.userSelected}})],1),_c('div',{staticClass:"md-layout-item md-large-size-33 md-medium-size-33 md-small-size-100"},[_c('md-button',{staticClass:"md-primary save-button",on:{"click":function($event){return _vm.saveCluster()}}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")])],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('md-list',[(_vm.mappingService.searchedOrDrawnItems.length > 0)?_c('div',[(_vm.typed && _vm.clusterName !== '')?_c('md-subheader',[_vm._v(" Search results for "+_vm._s(_vm.clusterName)+" ")]):_vm._e(),_vm._l((_vm.mappingService.searchedOrDrawnItems),function(geo,index){return _c('md-list-item',{key:index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.locationSelected(geo)}}},[_c('md-icon',{class:{
                      'selected-list-item': geo.selected,
                    }},[_vm._v(" location_on ")]),(geo.draw_type === 'draw')?_c('md-icon',{class:{
                      'selected-list-item': geo.selected,
                    }},[_vm._v(" edit ")]):_vm._e(),_c('span',{staticClass:"md-list-item-text"},[_vm._v(" "+_vm._s(geo.display_name)+" ")])],1)})],2):_vm._e(),(
                  _vm.mappingService.searchedOrDrawnItems.length < 1 &&
                  _vm.typed &&
                  _vm.clusterName !== ''
                )?_c('div',[_c('h4',{staticStyle:{"color":"#797979","margin-left":"1rem"}},[_vm._v(" "+_vm._s(_vm.$tc("phrases.newCluster", 2, { clusterName: _vm.clusterName, }))+" ")])]):_vm._e()])],1),_c('div',{staticClass:"md-layout-item md-size-100 map-area"},[_c('cluster-map',{ref:"clusterMapRef",attrs:{"mapping-service":_vm.mappingService,"polygon":true,"edit":true,"remove":true},on:{"customDrawnDeleted":_vm.customDrawnDeletedSet,"customDrawnEdited":_vm.customDrawnEditedSet}})],1)])])],1)],1),_c('md-dialog',{attrs:{"md-active":_vm.dialogActive,"md-close-on-esc":false,"md-click-outside-to-close":false},on:{"update:mdActive":function($event){_vm.dialogActive=$event},"update:md-active":function($event){_vm.dialogActive=$event}}},[_c('md-dialog-title',[_vm._v(" "+_vm._s(_vm.$tc("phrases.namingCluster"))+" ")]),_c('md-dialog-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100"},[_c('p',[_vm._v(" "+_vm._s(_vm.$tc("phrases.newClusterNotify", 0))+" ")])]),_c('div',{staticClass:"md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100"},[_c('md-field',{class:{
              'md-invalid': _vm.errors.has(_vm.$tc('words.name')),
            }},[_c('label',[_vm._v(_vm._s(_vm.$tc("words.name")))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"name":_vm.$tc('words.name')},model:{value:(_vm.clusterName),callback:function ($$v) {_vm.clusterName=$$v},expression:"clusterName"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.name")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100"},[_c('md-button',{staticClass:"md-primary save-button",on:{"click":function($event){return _vm.saveCluster()}}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }