<template>
  <CategoryManagement />
</template>

<script>
import CategoryManagement from "@/modules/Ticket/CategoryManagement"

export default {
  name: "TicketCategoryPage",
  components: { CategoryManagement },
}
</script>
