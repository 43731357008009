var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('widget',{attrs:{"color":"green","title":"Settings"}},[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-xsmall-size-100 md-medium-size-100 md-size-100"},[_c('md-card',{staticClass:"setting-card"},[_c('md-card-header',[_vm._v("Synchronization Settings")]),_c('md-card-content',[_c('form',{attrs:{"data-vv-scope":"Synchronization-Form"}},_vm._l((_vm.settingService.list),function(setting,i){return _c('div',{key:i},[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25"},[_c('md-field',[_c('label',[_vm._v(" "+_vm._s(setting.actionName)+" ")])])],1),_c('div',{staticClass:"md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25"},[_c('md-field',{class:{
                        'md-invalid': _vm.errors.has(
                          'Synchronization-Form.each_' + setting.id,
                        ),
                      }},[_c('label',{attrs:{"for":"per"}},[_vm._v("Each")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min_value:1'),expression:"'required|min_value:1'"}],attrs:{"min":"1","id":'each_' + setting.id,"name":'each_' + setting.id,"type":"number"},model:{value:(setting.syncInValueNum),callback:function ($$v) {_vm.$set(setting, "syncInValueNum", $$v)},expression:"setting.syncInValueNum"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first( "Synchronization-Form.each_" + setting.id, ))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25"},[_c('md-field',[_c('label',{attrs:{"for":"period"}},[_vm._v(" "+_vm._s(_vm.$tc("words.period"))+" ")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"period","id":"period"},model:{value:(setting.syncInValueStr),callback:function ($$v) {_vm.$set(setting, "syncInValueStr", $$v)},expression:"setting.syncInValueStr"}},_vm._l((_vm.syncPeriods),function(p,i){return _c('md-option',{key:i,attrs:{"value":p}},[_vm._v(" "+_vm._s(p)+"(s) ")])}),1)],1)],1),_c('div',{staticClass:"md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25"},[_c('md-field',{class:{
                        'md-invalid': _vm.errors.has(
                          'Synchronization-Form.max_attempt_' + setting.id,
                        ),
                      }},[_c('label',{attrs:{"for":"max_attempt"}},[_vm._v("Maximum Attempt(s)")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min_value:1'),expression:"'required|min_value:1'"}],attrs:{"id":'max_attempt_' + setting.id,"name":'max_attempt_' + setting.id,"type":"number","min":"1"},model:{value:(setting.maxAttempts),callback:function ($$v) {_vm.$set(setting, "maxAttempts", $$v)},expression:"setting.maxAttempts"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first( "Synchronization-Form.max_attempt_" + setting.id, ))+" ")])],1)],1)])])}),0)]),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised md-primary",on:{"click":function($event){return _vm.updateSyncSetting()}}},[_vm._v(" Save ")])],1),(_vm.loadingSync)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e()],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }