<template>
  <UserManagement />
</template>

<script>
import UserManagement from "@/modules/Ticket/UserManagement"
export default {
  name: "TicketUserPage",
  components: { UserManagement },
}
</script>

<style scoped></style>
