var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('widget',{attrs:{"title":_vm.$tc('phrases.newVillage'),"color":"green"}},[_c('md-card',{staticClass:"md-layout-item md-size-100"},[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter md-size-100"},[_c('div',{staticClass:"md-layout-item md-size-70 md-small-size-100"},[_c('md-field',{class:{
                'md-invalid': _vm.errors.has(_vm.$tc('words.name')),
              }},[_c('label',{attrs:{"for":"city_name"}},[_vm._v(" "+_vm._s(_vm.$tc("words.name"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"id":"cityName","name":_vm.$tc('words.name')},model:{value:(_vm.cityName),callback:function ($$v) {_vm.cityName=$$v},expression:"cityName"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.name")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-30 md-small-size-100"},[_c('md-field',{class:{
                'md-invalid': _vm.errors.has(_vm.$tc('words.miniGrid')),
              }},[_c('label',{attrs:{"for":"miniGrid"}},[_vm._v(" "+_vm._s(_vm.$tc("words.miniGrid"))+" ")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"miniGrid","id":"miniGrid"},model:{value:(_vm.selectedMiniGridId),callback:function ($$v) {_vm.selectedMiniGridId=$$v},expression:"selectedMiniGridId"}},_vm._l((_vm.miniGridService.list),function(mg){return _c('md-option',{key:mg.id,attrs:{"value":mg.id}},[_vm._v(" "+_vm._s(mg.name)+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.miniGrid")))+" ")])],1)],1)]),_c('div',{staticClass:"md-layout md-gutter md-size-100"},[_c('div',{staticClass:"md-layout md-gutter md-size-60 md-small-size-100",staticStyle:{"padding-left":"1.5rem !important"}},[_c('form',{staticClass:"md-layout md-gutter",staticStyle:{"padding-left":"1.5rem !important"},on:{"submit":function($event){$event.preventDefault();return _vm.setPoints.apply(null, arguments)}}},[_c('div',{staticClass:"md-layout-item md-size-30 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.latitude')),
                  }},[_c('label',{attrs:{"for":"latitude"}},[_vm._v(" "+_vm._s(_vm.$tc("words.latitude"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|decimal:5|max:8'),expression:"'required|decimal:5|max:8'"}],attrs:{"id":"latitude","name":_vm.$tc('words.latitude'),"step":"any","maxlength":"8"},model:{value:(_vm.cityLatLng.lat),callback:function ($$v) {_vm.$set(_vm.cityLatLng, "lat", $$v)},expression:"cityLatLng.lat"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.latitude")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-30 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has(_vm.$tc('words.longitude')),
                  }},[_c('label',{attrs:{"for":"longitude"}},[_vm._v(" "+_vm._s(_vm.$tc("words.longitude"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|decimal:5|max:8'),expression:"'required|decimal:5|max:8'"}],attrs:{"id":"longitude","name":_vm.$tc('words.longitude'),"step":"any","maxlength":"8"},model:{value:(_vm.cityLatLng.lon),callback:function ($$v) {_vm.$set(_vm.cityLatLng, "lon", $$v)},expression:"cityLatLng.lon"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.$tc("words.longitude")))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-40 md-small-size-100"},[_c('md-button',{staticClass:"md-primary set-button",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$tc("phrases.setPoints"))+" ")])],1)])]),_c('div',{staticClass:"md-layout-item md-size-40 md-small-size-100"},[_c('md-button',{staticClass:"md-primary save-button",on:{"click":_vm.saveVillage}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")])],1)]),_c('div',{staticClass:"md-layout-item md-size-100 map-area"},[_c('VillageMap',{ref:"villageMapRef",attrs:{"mapping-service":_vm.mappingService,"marker":true},on:{"locationSet":_vm.villageLocationSet}})],1)]),(_vm.loading)?_c('md-progress-bar',{staticClass:"md-progress-bar",attrs:{"md-mode":"indeterminate"}}):_vm._e()],1)],1),_c('redirection-modal',{attrs:{"redirection-url":_vm.redirectionUrl,"imperative-item":_vm.imperativeItem,"dialog-active":_vm.redirectDialogActive}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }