<template>
  <Dashboard />
</template>

<script>
import Dashboard from "@/modules/Cluster/Dashboard.vue"

export default {
  name: "ClusterDashboardPage",
  components: { Dashboard },
}
</script>

<style scoped></style>
